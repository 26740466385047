import Anchor from './Anchor';

const MeetThePI = () => {
    return (
        <section className="page">
            <div className="page-header">
                <div className="page-title">Meet the PI</div>
            </div>
            <article className="page-content">
                <div>
                    <div className="paragraph-start"></div>
                    Dr. Yi-Chieh Nancy Du is an Associate Professor at the Department of Pathology and Laboratory Medicine at <Anchor url="https://weill.cornell.edu/" label="Weill Cornell Medicine"/>. She has been deeply attracted to science since elementary school when she convinced her father to get her a microscope, chemicals, beakers, and test tubes to conduct experiments at home. To pursue her passion for scientific discovery, Dr. Du gave up the opportunity to enter medical school, and instead she joined <Anchor url="http://cls.life.nthu.edu.tw/" label="Department of Life Science at National Tsing Hua University (NTHU), Taiwan"/>. She received mentorship from <Anchor url="http://140.114.97.112/PCmemory/?page_id=3440" label="Dr. Pien-Chien Huang"/>, Dr. Tzong-Hsiung Hseu, and many other professors. She learned how to engineer protein mutations and purify proteins at NTHU and Yale University. Her undergraduate research experience broadened her perspective and had her yearning to remain in the lab day and night. In the lab Dr. Du discovered that the joy of conducting research greatly balances out against the frustration and sometimes the loneliness.
                    <br/><br/>
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    After graduating with a bachelor's degree from NTHU, she entered PhD program of State University of New York at Stony Brook. She was trained by <Anchor url="https://en.wikipedia.org/wiki/Bruce_William_Stillman" label="Dr. Bruce Stillman"/> at Cold Spring Harbor Laboratory. Dr. Stillman has made many significant achievements including the biochemical reconstitution with purified proteins of the complete replication of the SV40 DNA genome and the discovery of the Origin Recognition Complex (ORC). In Dr. Du’s graduate studies, she discovered a regulatory mechanism that coordinates cell proliferation with DNA replication and ribosome biogenesis using yeast (Du and Stillman, <i>Cell, 2002</i>). 
                    <br/><br/>
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    As a result of Dr. Du’s PhD work, she developed strong interests in cancer research and became a postdoctoral fellow of Nobel Laureate <Anchor url="https://en.wikipedia.org/wiki/Harold_E._Varmus" label="Dr. Harold Varmus"/> at Memorial Sloan Kettering Cancer Center. The most important work from her postdoctoral studies was to develop a mouse model, <i>RIP-Tag; RIP-tva</i>, to study metastasis (Du et al., <i>PLoS Biology, 2007</i>). Using a novel and innovative approach of employing somatic gene transfer system, this mouse model offers a unique opportunity to investigate molecular networks that drive the tumor progression to metastasis.
                    <br/><br/>
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    Dr. Du was recruited to Weill Cornell Medicine to develop a research laboratory that investigates the molecular mechanisms of cancer metastasis and develops novel therapeutic strategies, with the ultimate goal to save lives. Dr. Du and her team have expanded the scope from mouse models to human tissues and have been collaborating with other scientists and clinicians to better understand cancer metastasis. Dr. Du’s laboratory applies a multidisciplinary approach to analyze the molecular mechanism of metastasis, combining molecular biology and genomics tools with animal models and in vivo imaging technologies. Dr. Du’s laboratory identified several molecular targets of cancer and generated pre-clinical mouse models and cancer-specific nanoparticles as a drug delivery system. 
                    <br/><br/>
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    Dr. Du’s achievements have been recognized by Scholar-in-Training Award from the American Association for Cancer Research, Career Development Award from Department of Defense, President's Council of Cornell Women Affinito-Stewart Award, BCRP Breakthrough Award from Department of Defense, and Rasweiler Family Research Scholar in Cancer Research.
                    <br/><br/>
                </div>                
            </article>
        </section>
    );
}

export default MeetThePI;