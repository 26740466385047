import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Background from './components/Background';
import Footer from './components/Footer';
import Research from './components/Research';
import MeetThePI from './components/MeetThePI';
import Publications from './components/Publications';
import Team from './components/Team';
import Media from './components/Media';
import Resources from './components/Resources';
import JoinOurTeam from './components/JoinOurTeam';
import Contact from './components/Contact';
import Donate from './components/Donate';
import Keywords from './components/Keywords';
import PageNotFound from './components/PageNotFound';
import './App.css';

function App() {
    return (
        <>
        <div className="body-wrapper">
            <div className="body-width-limited">
                <Header/>
                <Background/>
                <main className="contents">
                    <Routes>
                        <Route path="/*" element={<PageNotFound/>}/>
                        <Route path="/research" element={<Research/>}/>
                        <Route path="/meet-the-pi" element={<MeetThePI/>}/>
                        <Route path="/publications" element={<Publications/>}/>
                        <Route path="/team" element={<Team/>}/>
                        <Route path="/media" element={<Media/>}/>
                        <Route path="/resources" element={<Resources/>}/>
                        <Route path="/join-our-team" element={<JoinOurTeam/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/donate" element={<Donate/>}/>
                    </Routes>
                    <Footer/>
                </main>
            </div>
        </div>
        <Keywords/>
        </>
    );
}

export default App;
