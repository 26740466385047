export const members = [
    {
        title: 'Postdoctoral Fellow',
        name: 'Tiantian Zhang',
        avatar: 'tiantian.png',
    },
    {
        title: 'Postdoctoral Fellow',
        name: 'Xiang Chen',
        avatar: 'xiang.png',
    },
    {
        title: 'Research Technician',
        name: 'Cheryl Zhang',
        avatar: 'cheryl.png',
    },
    {
        title: 'Research Technician',
        name: 'Michelle Chen',
        avatar: 'michelle.png',
    },    
    {
        title: 'Research Assistant',
        name: 'Janie Chan',
        avatar: 'janie.png',
    },
    {
        title: 'Research Assistant',
        name: 'Raheem S. Sheikh',
        avatar: 'raheem.png',
    },
    {
        title: 'Research Assistant',
        name: 'Sathirtha Mondal',
        avatar: 'sathirtha.png',
    },    
    {
        title: 'Research Assistant',
        name: 'Marjona Mirzaeva',
        avatar: 'marjona.png',
    },       
]