import MediaTemplate from './MediaTemplate';

const Media = () => {
    return (
        <section className="page">
            <div className="page-header">
                <div className="page-title">Media</div>
            </div>
            <article className="page-content">
                <MediaTemplate 
                    media="Radio Golden Vintage"
                    label="Invited Speaker, Technology and Life program"
                    link="http://www.ximalaya.com/65089281/sound/52141069/"
                />
			    <MediaTemplate
                    media="Weill Cornell News"
                    label="Breast cancer awards advance efforts to address metastasis and harness immune system."
                    sub="By Weill Cornell News"
                    link="https://meyercancer.weill.cornell.edu/news/2016-11-08/breast-cancer-awards-advance-efforts-address-metastasis-and-harness-immune-system"
			    />
			    <MediaTemplate
                    media="World Biomedical Frontiers"
                    label="Expression of the receptor for hyaluronic acid mediated motility (RHAMM) is associated with poor prognosis and metastasis in non-small cell lung carcinoma."
                    sub="By World Biomedical Frontiers"
                    link="http://biomedfrontiers.org/cancer-2016-6-17/"
			    />
			    <MediaTemplate
				    media="Weill Cornell Newsroom"
				    label="Protein Critical to Cell Survival Also Promotes Metastasis Independently."
				    sub="By Weill Cornell Newsroom"
				    link="http://weill.cornell.edu/news/news/2016/01/protein-critical-to-cell-survival-also-promotes-metastasis-independently.html"
			    />
			    <MediaTemplate
                    media="Nature Methods"
                    label="There is a virus going around."
                    sub="By Stevens K."
                    link="http://www.nature.com/nmeth/journal/v5/n1/full/nmeth0108-6b.html"
                    note="2008. 5, 6-7"
			    />			
			    <MediaTemplate
				    media="Cancer Cell"
				    label="Leaving home early: reexamination of the canonical models of tumor progression."
				    sub="By Weinberg RA."
				    link="http://www.ncbi.nlm.nih.gov/pubmed/18835030"
                    note="2008 Oct 7;14(4):283-4"
			    />
			    <MediaTemplate
				    media="Nature Reviews Molecular Biology"
				    label="DNA replication: a complex landing."
				    sub="By Mitchell A."
				    link="http://www.nature.com/nrm/journal/v3/n8/full/nrm887.html"
                    note="2002 August. 3, 550"
			    />
			    <MediaTemplate
				    media="Journal of Cell Biology"
				    label="Coordination of replication."
				    sub="By LeBrasseur N."
				    link="http://www.ncbi.nlm.nih.gov/pmc/articles/PMC2244939/?report=classic"
                    note="2002 August. 158, 385"
                />
			    <MediaTemplate
				    media="Trends in Cell Biology"
				    label="Ribosome assembly reawakens."
				    sub="By Sadler KC."
				    link="http://www.sciencedirect.com/science/article/pii/S0962892402023796"
                    note="2002 September. 12, 411"
                />
			    <br/><br/>
            </article>
        </section>
    );
}

export default Media;