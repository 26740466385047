export const alumnis = [
    {
        name: 'Anthony (Tony) Daniyan',
        title: 'Resident',
        year: '2010-2012',
        now: 'Assistant Attending, Memorial Sloan Kettering Cancer Center',
        accomplishments: 'Co-author on 1 Publication; National Heart, Lung, and Blood Institute (NHLBI) Cardiovascular Training Grant Recipient; NIH Division of Loan Repayment Award',
        nodash: 'true',
    },
    {
        name: 'Leigh Selesner',
        title: 'Research Technician',
        year: '2010-2014',
        now: 'Resident, Oregon Health and Sciences University in Portland',
        accomplishments: 'Co-author on 1 Publication',
    },
    {
        name: 'Soyoung Choi',
        title: 'Postdoctoral Fellow',
        year: '2012-2016',
        now: 'Principal investigator, Samsung Bioepis, South Korean',
        accomplishments: 'First author on 2 Publications; co-author on 1 Publication',
    },
    {
        name: 'Stephanie Azzopardi',
        title: 'Undergraduate Student', 
        year: '2012-2016',
        now: 'WCM MD/PhD student',
        accomplishments: 'First author on 1 Publication; co-author on 1 Publication; 2015 Barry Goldwater Scholarship and Excellence in Education Foundation, Honorable Mention.',
    },
    { 
        name: 'Sharon Pang',
        title: 'Undergraduate Student',
        year: '2014-2017',
        education: 'M.D., Johns Hopkins University School of Medicine',
        now: 'Harvard Affiliated Emergency Medicine Residency at Mass General Brigham',
        accomplishments: 'Best medical science poster at 2016 Undergraduate Research Conference; Co-author on 3 Publications',
    },
    {
        name: 'Bu Jung Kim',
        title: 'Research Technician',
        year: '2014-2015, 2019',
        education: 'M.D., Florida International University Herbert Wertheim College of Medicine',
        now: 'Family Medicine Physician, Geisinger Health Center',
        accomplishments: 'Co-author on 2 Publications',
    },
    {
        name: 'Samantha Li',
        title: 'High School Student',
        year: '2014-2017',
        now: 'Graduate student, Columbia University',
        accomplishments: 'Co-author on 1 Publication',
    },
    { 
        name: 'Megan Wong',
        title: 'Undergraduate Student',
        year: '2015-2018',
        education: 'Master of Science in Medical Physiology, Case Western Reserve University',
        now: 'PhD student, SUNY Downstate',
        accomplishments: 'Co-author on 1 Publication',
    },
    {
        name: 'George Zhang',
        title: 'Research Technician',
        year: '2016-2019',
        now: 'MD student, University of Vermont Larner College of Medicine',
        accomplishments: 'First author on 2 Publications; co-author on 5 Publications',
    },
    {
        name: 'Joseph HyungJoon Na',
        title: 'Research Technician',
        year: '2017-2019',
        now: 'MD student, University of Buffalo',
        accomplishments: 'co-author on 2 Publications',
    },
    { 
        name: 'Shihui Wang',
        title: 'Undergraduate Student',
        year: '2017-2018',
        now: 'DDS student, NYU College of Dentistry',
        accomplishments: '2017 NYU Dean\'s Undergraduate Research Fund Grant',
    },
    {
        name: 'Makheni Jean-Pierre',
        title: 'Tri-Institutional Gateways to the Laboratory Summer Student',
        year: '2018',
        now: 'NIH UGSP Postbac',
        accomplishments: '2018/2019 Undergraduate Research & Creative Activities (URECA) Travel grant; 2019 NIH Undergraduate Scholarship (UGSP)',
    },
    { 
        name: 'Sha Li',
        title: 'Postdoctoral Fellow',
        year: '2017-2018',
        now: 'Postdoctoral Fellow',
        accomplishments: '2019 Presidential Postdoctoral Research Fellowship, Princeton University',
    },
    {
        name: 'Jennifer Feng',
        title: 'Undergraduate Student',
        year: '2018-2021',
        now: 'MD student, Hackensack Medical School',
        accomplishments: 'Co-first author on 1 Publication',
    },
    { 
        name: 'Anthony Lin',
        title: 'Research Technician',
        year: '2019-2023',
        now: 'MD student, Lake Erie College of Osteopathic Medicine',
        accomplishments: 'Co-first author on 1 Publication',
    },
    {
        name: 'Sandi Bajrami',
        title: 'Research Assistant',
        year: '2020-2022',
        now: 'MD student, Stony Brook School of Medicine',
        accomplishments: 'Co-author on 1 Publication',
    },
];