export const publications = [
    {
        authors: 'Wang D, Qian X, Du YCN, Sanchez-Solana B, Chen K, Kanigicherla M, Jenkins MLM, Luo J, Eng S, Park B, Chen B, Yao X, Nguyen T, Tripathi BK, Durkin ME, Lowy DR',
        link: 'https://www.fortunejournals.com/articles/cprosite-a-web-based-interactive-platform-for-online-proteomics-phosphoproteomics-and-genomics-data-analysis.html',
        label: 'cProSite: A Web Based Interactive Platform for Online Proteomics, Phosphoproteomics, and Genomics Data Analysis',
        publisher: 'J Biotechnol Biomed 2023 DOI:10.26502/jbb.2642-91280119',
    },    
    {
        authors: 'Wang B, Du YCN',
        link: 'https://apc.amegroups.com/article/view/7187/html',
        label: 'The anti-metastasis effect of low-dose carbon monoxide',
        publisher: 'Annals of Pancreatic Cancer. 2023',
    },
    {
        authors: 'Greenberg J, Limberg J, Verma A, Kim D, Chen X, Lee YJ, Moore MD, Ullmann TM, Thiesmeyer JW, Loewenstein Z, Chen KJ, Egan CE, Stefanova D, Bareja R, Zarnegar R, Finnerty BM, Scognamiglio T, Du YCN, Elemento O, Fahey Iii TJ, Min IM',
        link: 'https://pubmed.ncbi.nlm.nih.gov/36301668/',
        label: 'Metastatic pancreatic neuroendocrine tumors manifest elevated T cell infiltration',
        publisher: 'JCI Insight. 2022 Oct 27;. doi: 10.1172/jci.insight.160130. [Epub ahead of print] PubMed PMID: 36301668',
    },
    {
        authors: 'Lin M, Ku AT, Dong J, Yue F, Jiang W, Ibrahim AA, Peng F, Creighton CJ, Nagi C, Gutierrez C, Rosen JM, Zhang XH, Hilsenbeck SG, Chen X, Du YCN, Huang S, Shi A, Fan Z, Li Y',
        link: 'https://pubmed.ncbi.nlm.nih.gov/36261627/',
        label: 'STAT5 confers lactogenic properties in breast tumorigenesis and restricts metastatic potential',
        publisher: 'Oncogene. 2022 Oct 19;. doi: 10.1038/s41388-022-02500-w. [Epub ahead of print] PubMed PMID: 36261627',
    },
    {
        authors: 'Zhang T, Zhang G, Chen X, Chen Z, Tan AY, Lin A, Zhang C, Torres LK, Bajrami S, Zhang T, Zhang G, Xiang JZ, Hissong EM, Chen YT, Li Y, Du YCN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/35868533/',
        label: 'Low-dose carbon monoxide suppresses metastatic progression of disseminated cancer cells',
        publisher: 'Cancer Lett. 2022 Oct 10;546:215831. doi: 10.1016/j.canlet.2022.215831. Epub 2022 Jul 19. PubMed PMID: 35868533',
    },
    {
        authors: 'Chen X and Du YCN',
        link: 'https://apc.amegroups.com/article/view/6929/html',
        label: 'RHAMM knockout” mice express a truncated RHAMM protein that promotes pancreatic cancer progression with dysfunctional p53',
        publisher: 'Annals of pancreatic cancer. 2022. NIHMSID: NIHMS1821921',
    },											
    {
        authors: 'Young A, Bu W, Jiang W, Ku A, Kapali J, Dhamne S, Qin L, Hilsenbeck SG, Du YN, Li Y',
        link: 'https://pubmed.ncbi.nlm.nih.gov/34667127/',
        label: 'Targeting the Pro-survival Protein BCL-2 to Prevent Breast Cancer',
        publisher: 'Cancer Prev Res (Phila). 2022 Jan;15(1):3-10. doi: 10.1158/1940-6207.CAPR-21-0031. Epub 2021 Oct 19. PubMed PMID: 34667127; PubMed Central PMCID: PMC8741732',
    },
    {
        authors: 'Chen X, Lee SK, Song M, Zhang T, Han MS, Chen YT, Chen Z, Ma X, Tung CH, Du YN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/34761107/',
        label: 'RHAMM<sup>B</sup>-mediated bifunctional nanotherapy targeting Bcl-xL and mitochondria for pancreatic neuroendocrine tumor treatment',
        publisher: 'Mol Ther Oncolytics. 2021 Dec 17;23:277-287. doi: 10.1016/j.omto.2021.10.002. eCollection 2021 Dec 17. PubMed PMID: 34761107; PubMed Central PMCID: PMC8560716',
    },
    {
        authors: 'Lin A, Feng J, Chen X, Wang D, Wong M, Zhang G, Na J, Zhang T, Chen Z, Chen YT, Nancy Du YC',
        link: 'https://pubmed.ncbi.nlm.nih.gov/34044069/',
        label: 'High levels of truncated RHAMM cooperate with dysfunctional p53 to accelerate the progression of pancreatic cancer',
        publisher: 'Cancer Lett. 2021 Aug 28;514:79-89. doi: 10.1016/j.canlet.2021.05.011. Epub 2021 May 24. PubMed PMID: 34044069; PubMed Central PMCID: PMC8235875',
    },
    {
        authors: 'Zhang T, Na JH, Li S, Chen Z, Zhang G, Pang S, Daniyan AF, Li Y, Shi L, Du YN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/34308416/',
        label: 'Functional impact of cancer patient-associated Bcl-xL mutations',
        publisher: 'MedComm (2020). 2020 Dec;1(3):328-337. doi: 10.1002/mco2.36. Epub 2020 Oct 29. PubMed PMID: 34308416; PubMed Central PMCID: PMC8302207',
    },
    {
        authors: 'Schatz-Siemers N, Chen YT, Chen Z, Wang D, Ellenson LH, Du YN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/30920393/',
        label: 'Expression of the Receptor for Hyaluronic Acid-Mediated Motility (RHAMM) in Endometrial Cancer is Associated With Adverse Histologic Parameters and Tumor Progression',
        publisher: 'Appl Immunohistochem Mol Morphol. 2020 Jul;28(6):453-459. doi: 10.1097/PAI.0000000000000763. PubMed PMID: 30920393; PubMed Central PMCID: PMC7546253',
    },
    {
        authors: 'Zhang T, Choi S, Zhang T, Chen Z, Chi Y, Huang S, Xiang JZ, Du YN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/31953039/',
        label: 'miR-431 Promotes Metastasis of Pancreatic Neuroendocrine Tumors by Targeting DAB2 Interacting Protein, a Ras GTPase Activating Protein Tumor Suppressor',
        publisher: 'Am J Pathol. 2020 Mar;190(3):689-701. doi: 10.1016/j.ajpath.2019.11.007. Epub 2020 Jan 14. PubMed PMID: 31953039; PubMed Central PMCID: PMC7074368',
    }, 
    {
        authors: 'Goren L, Zhang G, Kaushik S, Breslin PAS, Du YN, Foster DA',
        link: 'https://pubmed.ncbi.nlm.nih.gov/31412041/',
        label: '(-)-Oleocanthal and (-)-oleocanthal-rich olive oils induce lysosomal membrane permeabilization in cancer cells',
        publisher: 'PLoS One. 2019;14(8):e0216024. doi: 10.1371/journal.pone.0216024. eCollection 2019. PubMed PMID: 31412041; PubMed Central PMCID: PMC6693737',
    },
    {
        authors: 'Buicko JL, Finnerty BM, Zhang T, Kim BJ, Fahey TJ 3rd, Nancy Du YC',
        link: 'https://pubmed.ncbi.nlm.nih.gov/31535089/',
        label: 'Insights into the biology and treatment strategies of pancreatic neuroendocrine tumors',
        publisher: 'Ann Pancreat Cancer. 2019 Jun;2. doi: 10.21037/apc.2019.06.02. Epub 2019 Jun 20. PubMed PMID: 31535089; PubMed Central PMCID: PMC6750261',
    },	
    {
        authors: 'Choi S, Wang D, Chen X, Tang LH, Verma A, Chen Z, Kim BJ, Selesner L, Robzyk K, Zhang G, Pang S, Han T, Chan CS, Fahey TJ 3rd, Elemento O, Du YN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/31072393/',
        label: 'Function and clinical relevance of RHAMM isoforms in pancreatic tumor progression',
        publisher: 'Mol Cancer. 2019 May 9;18(1):92. doi: 10.1186/s12943-019-1018-y. PubMed PMID: 31072393; PubMed Central PMCID: PMC6506944',
    },
    {
        authors: 'Finnerty BM, Moore MD, Verma A, Aronova A, Huang S, Edwards DP, Chen Z, Seandel M, Scognamiglio T, Du YN, Elemento O, Zarnegar R, Min IM, Fahey TJ',
        link: 'https://pubmed.ncbi.nlm.nih.gov/30689542/',
        label: 'UCHL1 loss alters the cell-cycle in metastatic pancreatic neuroendocrine tumors',
        publisher: 'Endocr Relat Cancer. 2019 Apr 1;26(4):411-423. doi: 10.1530/ERC-18-0507. Epub 2019 Jan 1. PubMed PMID: 30689542',
    },
    {
        authors: 'Yuan Z, Gardiner JC, Maggi EC, Adem A, Zhang G, Lee S, Romanienko P, Du YN, Libutti SK',
        link: 'https://pubmed.ncbi.nlm.nih.gov/30190513/',
        label: 'Tissue-specific induced DNA methyltransferase 1 (Dnmt1) in endocrine pancreas by RCAS-TVA-based somatic gene transfer system promotes β-cell proliferation',
        publisher: 'Cancer Gene Ther. 2019 Mar;26(3-4):94-102. doi: 10.1038/s41417-018-0046-x. Epub 2018 Sep 7. PubMed PMID: 30190513; PubMed Central PMCID: PMC7540611',
    },	
    {
        authors: 'Kobayashi S, Contractor T, Vosburgh E, Du YN, Tang LH, Clausen R, Harris CR',
        link: 'https://pubmed.ncbi.nlm.nih.gov/30796198/',
        label: 'Alleles of Insm1 determine whether RIP1-Tag2 mice produce insulinomas or nonfunctioning pancreatic neuroendocrine tumors',
        publisher: 'Oncogenesis. 2019 Feb 22;8(3):16. doi: 10.1038/s41389-019-0127-1. PubMed PMID: 30796198; PubMed Central PMCID: PMC6386750',
    },
    {
        authors: 'Zhang G, Du YN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/30378065/',
        label: 'Orthotopic Pancreatic Tumor Mouse Models of Liver Metastasis',
        publisher: 'Methods Mol Biol. 2019;1882:309-320. doi: 10.1007/978-1-4939-8879-2_27. PubMed PMID: 30378065; PubMed Central PMCID: PMC6800204',
    },
    {
        authors: 'Chen YT, Chen Z, Du YN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/29765511/',
        label: 'Immunohistochemical analysis of RHAMM expression in normal and neoplastic human tissues: a cell cycle protein with distinctive expression in mitotic cells and testicular germ cells',
        publisher: 'Oncotarget. 2018 Apr 20;9(30):20941-20952. doi: 10.18632/oncotarget.24939. eCollection 2018 Apr 20. PubMed PMID: 29765511; PubMed Central PMCID: PMC5940366',
    },
    {
        authors: 'Zhang G, Chi Y, Du YN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/29155705/',
        label: 'Identification and Characterization of Metastatic Factors by Gene Transfer into the Novel RIP-Tag; RIP-tva Murine Model',
        publisher: 'J Vis Exp. 2017 Oct 16;(128). doi: 10.3791/55890. PubMed PMID: 29155705; PubMed Central PMCID: PMC5644377',
    },	
    {
        authors: 'Azzopardi S, Pang S, Klimstra DS, Du YN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/27664376/',
        label: 'p53 and p16<sup>Ink4a</sup>/p19<sup>Arf</sup> Loss Promotes Different Pancreatic Tumor Types from PyMT-Expressing Progenitor Cells',
        publisher: 'Neoplasia. 2016 Oct;18(10):610-617. doi: 10.1016/j.neo.2016.08.003. Epub 2016 Sep 21. PubMed PMID: 27664376; PubMed Central PMCID: PMC5035259',
    },
    {
        authors: 'Wang D, Narula N, Azzopardi S, Smith RS, Nasar A, Altorki NK, Mittal V, Somwar R, Stiles BM, Du YN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/27220886/',
        label: 'Expression of the receptor for hyaluronic acid mediated motility (RHAMM) is associated with poor prognosis and metastasis in non-small cell lung carcinoma',
        publisher: 'Oncotarget. 2016 Jun 28;7(26):39957-39969. doi: 10.18632/oncotarget.9554. PubMed PMID: 27220886; PubMed Central PMCID: PMC5129984',
    },               
    {
        authors: 'Yuan Z, Sánchez Claros C, Suzuki M, Maggi EC, Kaner JD, Kinstlinger N, Gorecka J, Quinn TJ, Geha R, Corn A, Pastoriza J, Jing Q, Adem A, Wu H, Alemu G, Du YC, Zheng D, Greally JM, Libutti SK',
        link: 'https://pubmed.ncbi.nlm.nih.gov/26871472/',
        label: 'Loss of MEN1 activates DNMT1 implicating DNA hypermethylation as a driver of MEN1 tumorigenesis',
        publisher: 'Oncotarget. 2016 Mar 15;7(11):12633-50. doi: 10.18632/oncotarget.7279. PubMed PMID: 26871472; PubMed Central PMCID: PMC4914310',
    },              
    {
        authors: 'Choi S, Chen Z, Tang LH, Fang Y, Shin SJ, Panarelli NC, Chen YT, Li Y, Jiang X, Du YN',
        link: 'https://pubmed.ncbi.nlm.nih.gov/26785948/',
        label: 'Bcl-xL promotes metastasis independent of its anti-apoptotic activity',
        publisher: 'Nat Commun. 2016 Jan 20;7:10384. doi: 10.1038/ncomms10384. PubMed PMID: 26785948; PubMed Central PMCID: PMC4735924',
    },
    {
        authors: 'Tang LH, Contractor T, Clausen R, Klimstra DS, Du YC, Allen PJ, Brennan MF, Levine AJ, Harris CR',
        link: 'https://pubmed.ncbi.nlm.nih.gov/22761470/',
        label: 'Attenuation of the retinoblastoma pathway in pancreatic neuroendocrine tumors due to increased cdk4/cdk6',
        publisher: 'Clin Cancer Res. 2012 Sep 1;18(17):4612-20. doi: 10.1158/1078-0432.CCR-11-3264. Epub 2012 Jul 3. PubMed PMID: 22761470',
    },
    {
        authors: 'Du YC, Chou CK, Klimstra DS, Varmus H',
        link: 'https://pubmed.ncbi.nlm.nih.gov/21940500/',
        label: 'Receptor for hyaluronan-mediated motility isoform B promotes liver metastasis in a mouse model of multistep tumorigenesis and a tail vein assay for metastasis',
        publisher: 'Proc Natl Acad Sci U S A. 2011 Oct 4;108(40):16753-8. doi: 10.1073/pnas.1114022108. Epub 2011 Sep 21. PubMed PMID: 21940500; PubMed Central PMCID: PMC3189086',
    },
    {
        authors: 'Reddy JP, Peddibhotla S, Bu W, Zhao J, Haricharan S, Du YC, Podsypanina K, Rosen JM, Donehower LA, Li Y',
        link: 'https://pubmed.ncbi.nlm.nih.gov/20133707/',
        label: 'Defining the ATM-mediated barrier to tumorigenesis in somatic mammary cells following ErbB2 activation',
        publisher: 'Proc Natl Acad Sci U S A. 2010 Feb 23;107(8):3728-33. doi: 10.1073/pnas.0910665107. Epub 2010 Feb 3. PubMed PMID: 20133707; PubMed Central PMCID: PMC2840493',
    },
    {
        authors: 'Du YC, Klimstra DS, Varmus H',
        link: 'https://pubmed.ncbi.nlm.nih.gov/19812721/',
        label: 'Activation of PyMT in beta cells induces irreversible hyperplasia, but oncogene-dependent acinar cell carcinomas when activated in pancreatic progenitors',
        publisher: 'PLoS One. 2009 Sep 7;4(9):e6932. doi: 10.1371/journal.pone.0006932. PubMed PMID: 19812721; PubMed Central PMCID: PMC2758666',
    },
    {
        authors: 'Podsypanina K, Du YC, Jechlinger M, Beverly LJ, Hambardzumyan D, Varmus H',
        link: 'https://pubmed.ncbi.nlm.nih.gov/18755941/',
        label: 'Seeding and propagation of untransformed mouse mammary cells in the lung',
        publisher: 'Science. 2008 Sep 26;321(5897):1841-4. doi: 10.1126/science.1161621. Epub 2008 Aug 28. PubMed PMID: 18755941; PubMed Central PMCID: PMC2694414',
    },
    {
        authors: 'Du YC, Lewis BC, Hanahan D, Varmus H',
        link: 'https://pubmed.ncbi.nlm.nih.gov/17941720/',
        label: 'Assessing tumor progression factors by somatic gene transfer into a mouse model: Bcl-xL promotes islet tumor cell invasion',
        publisher: 'PLoS Biol. 2007 Oct 16;5(10):e276. doi: 10.1371/journal.pbio.0050276. PubMed PMID: 17941720; PubMed Central PMCID: PMC2020504',	
    },
    {
        authors: 'Varmus H, Pao W, Politi K, Podsypanina K, Du YC',
        link: 'https://pubmed.ncbi.nlm.nih.gov/16869733/',
        label: 'Oncogenes come of age',
        publisher: 'Cold Spring Harb Symp Quant Biol. 2005;70:1-9. doi: 10.1101/sqb.2005.70.039. Review. PubMed PMID: 16869733; PubMed Central PMCID: PMC1791364',
    },
    {
        authors: 'Du YC, Stillman B',
        link: 'https://pubmed.ncbi.nlm.nih.gov/12110181/',
        label: 'Yph1p, an ORC-interacting protein: potential links between cell proliferation control, DNA replication, and ribosome biogenesis',
        publisher: 'Cell. 2002 Jun 28;109(7):835-48. doi: 10.1016/s0092-8674(02)00773-0. PubMed PMID: 12110181; NIHMSID:NIHMS501519',
    },
];