const production = document.location.hostname.indexOf('staging') === -1;

export const siteTitle = `${production ? '' : '[Staging] '}Nancy Du Lab`;

export const menuItems = [
    'research',
    'meet-the-pi',
    'publications',
    'team',
    'media',
    'resources',
    'join-our-team',
    'contact',
    'donate'
];