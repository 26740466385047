const Research = () => {
    return (
        <section className="page">
            <div className="page-header">
                <div className="page-title">Research</div>
            </div>
            <article className="page-content">
                <div>
                    <div className="paragraph-start"></div>
                    Ninety percent of cancer patients do not die of locally confined cancer, but rather from cancer that has spread, leading to diminished function of vital organs. There is an urgent need to better understand drivers of cancer metastasis and to identify novel therapeutic targets.
                    <br/><br/>
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    We want to help patients through our research.  The focus in Nancy Du laboratory is to understand the molecular mechanisms underlying metastasis.  Our long-term goal is to develop therapeutic strategies to prevent metastatic tumor formation and inhibit metastatic tumor growth.  We have developed novel model systems to study the in vivo effects of candidate genes in tumor progression.  Major research areas include: identification and functional characterization of metastasis genes and non-coding RNAs, and pre-clinical evaluation of anti-tumor therapeutics.
                    <br/><br/>
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    In many human cancers, the gene that produces RHAMM is over-expressed, leading to too much RHAMM protein.  While it is unclear whether RHAMM itself plays a causal role in tumor initiation or progression, we demonstrated that an alternative form of RHAMM, RHAMM<sup>B</sup> or RHAMMv3, promotes the growth of pancreatic tumors and their spread to lymph nodes and the liver.  It was thought that pancreatic cancer metastasizes naturally to the liver simply because the two organs are next to each other in the abdomen.  But in a seminal experiment, we identified RHAMM<sup>B</sup> as the first protein to promote liver-specific metastasis of pancreatic neuroendocrine tumors.  In addition, we and others found that overexpression of RHAMM predicts poor survival in cancer patients.
                    <br/><br/>
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    Bcl-xL is another gene that is over-expressed in human cancers, including pancreatic cancer and breast cancer.  This protein is normally present in the mitochondrial compartment of a cell, and has been known for its function in preventing a common, programmed form of cell death (apoptosis).  When Bcl-xL is overexpressed in cancer, it helps cancer cells to survive.  Several drugs have been developed to inhibit the anti-apoptotic function of Bcl-xL.  Unfortunately, these drugs have limited therapeutic value in clinical trials by themselves.  We demonstrated that in cancer, Bcl-xL travels to the nucleus to promote metastasis independent of its anti-apoptotic function.  Our studies suggested a paradigm-shifting insight of Bcl-xL.  This unexpected finding provides a possible explanation for why clinical trials using drugs to block anti-apoptosis roles of Bcl-xL have not been effective in halting cancer progression.
                    <br/><br/>
                </div>                
            </article>
        </section>
    );
}

export default Research;