import Anchor from './Anchor';

const MediaTemplate = (props) => {
    const sub = props.sub ? props.sub : '';
    const anchorLabel = `${props.label}<sub><sup><sub>&nbsp;${sub}</sub></sup></sub>`;
    
    return (
        <>
            <div className="in-page-title">{props.media}</div>
            <ul className="indent-double">
                <li className="media-list-item">
                    <Anchor url={props.link} label={anchorLabel}/>
                </li>
            </ul>
        </>
    );
}

export default MediaTemplate;