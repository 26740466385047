import TeamMemberProfile from './TeamMemberProfile';
import AlumniTemplate from './AlumniTemplate';
import { members } from '../data/Members';
import { alumnis } from '../data/Alumnis';

const Team = () => {
    return (
        <section className="page">
            <div className="page-header">
                <div className="page-title">Team</div>
            </div>
            <article className="page-content">
                <div className="member-wrapper">
                    <TeamMemberProfile
                        title="Principal Investigator"
                        name="Yi-Chieh Nancy Du"
                        avatar="nancy.png"
                    />
                </div>
                <div className="member-wrapper">
                {
                    members.map((member, index) => {
                        return (
                            <TeamMemberProfile
                                key={member.name}
                                index={index}
                                title={member.title}
                                name={member.name}
                                avatar={member.avatar}
                            />
                        );
                    })
                }
                </div>
                <div className="in-page-title">
				    Alumni
			    </div>
                {
                    alumnis.map((alumni, index) => {
                        return (<AlumniTemplate
                            key={alumni.name}
                            index={index}
                            name={alumni.name}
                            title={alumni.title}
                            year={alumni.year}
                            education={alumni.education}
                            now={alumni.now}
                            accomplishments={alumni.accomplishments}
                        />);
                    })
                }
            </article>
        </section>
    );
}

export default Team;