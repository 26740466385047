import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { siteTitle, menuItems } from '../infra/constants';
import { setMenuLabel } from '../infra/functions';

const Menu = () => {
    const currPathName = document.location.pathname.split('/')[1];
    const menuPath = menuItems.includes(currPathName) ? currPathName : 'page-not-found';
    
    // auto forward to the first page from the root
    if (currPathName === '') {
        document.location.replace(`/${menuItems[0]}`);
    }

    const [currMenu, setCurrMenu] = useState(currPathName);

    const setBackgroundImage = menuPath => {
        document.querySelector('#backgroundImage').style.backgroundImage = `url(/images/header-image-${menuPath}.jpg)`;
    }

    const labelToPath = txt => {
        return txt.toLowerCase().replace(/ /g, '-');
    }

    const showHideMobileMenu = (disp) => {
        const mobilemenu = document.querySelector('.mobile-menu-container');
        mobilemenu.style.display = disp;
    }

    const displayMobileMenu = () => {
        showHideMobileMenu('flex');
    }    

    const handleMenuClick = obj => {
        const menuPath = labelToPath(obj.target.innerHTML);
        setCurrMenu(menuPath);
        showHideMobileMenu('none');
        window.scrollTo(0, 0);
    }

    const handleCloseClick = () => {
        showHideMobileMenu('none');
    }

    useEffect(() => {
        document.title = `${siteTitle} - ${setMenuLabel(menuPath)}`;
        setBackgroundImage(menuPath);
    }, [menuPath]);    

    return (
        <>
        <div>
            <div className="menu-icon" onClick={displayMobileMenu}>&#9776;</div>
			<nav className="navigation">
                {
                    menuItems.map((m, idx) => {
                        return (
                            <Link 
                                key={m} to={'/' + m}
                                className={(currMenu === m ? 'navigation-item-on' : 'navigation-item')}
                                onClick={handleMenuClick}>
                                    {setMenuLabel(m)}
                            </Link>
                        )
                    })
                }
			</nav>
        </div>
        <div className="mobile-menu-container">
            {
                menuItems.map((m, idx) => {
                    return (
                        <div key={m} className="mobile-menu-item">
                            <Link
                                to={'/' + m}
                                onClick={handleMenuClick}>
                                    {setMenuLabel(m)}
                            </Link>
                        </div>
                    );
                })
            }
            <div className="mobile-menu-item">&nbsp;</div>
            <div className="close-icon" onClick={handleCloseClick} dangerouslySetInnerHTML={{__html: '&#10006'}}></div>
        </div>
        </>
    );
}

export default Menu;