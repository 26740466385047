import Anchor from './Anchor';

const Resources = () => {
    return (
        <section className="page">
            <div className="page-header">
                <div className="page-title">Resources</div>
            </div>
            <article className="page-content">
                <div className="in-page-title">
                    <i>RIP-Tag; RIP-tva</i> preclinical mouse model
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    The use of a powerful mouse model, <i>RIP-Tag</i>, has provided significant insights for the molecular mechanism of tumorigenesis in general, far beyond pancreatic neuroendocrine tumors (panNETs). In this model, the rat insulin promoter (RIP) drives the expression of the SV40 T antigen (Tag) in pancreatic &beta; cells. The mice develop tumors through well-defined stages that are similar to human tumorigenesis, including hyperplasia, angiogenesis, adenoma, and invasive carcinoma in four months. However, this valuable preclinical mouse model does not develop metastatic disease. 
                    <br/><br/>
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    To investigate molecular networks that drive the progression from primary cancer to metastasis, we have developed a bitransgenic mouse model, <i>RIP-Tag; RIP-tva</i>, in which the receptor for subgroup A avian leukosis virus (<i>RIP-tva</i>) is also expressed in pancreatic &beta; cells. As such, genetic alterations can be introduced <i>in vivo</i> into pancreatic &beta; cells by infection with avian retroviral vectors harboring desired genetic alteration (Figure 1). This approach has the advantage of introducing somatic genetic changes specifically into premalignant lesions of pancreatic &beta; cells in a time-control manner, thus more faithfully mimicing sporadic tumor development. This approach also avoids any potential perturbation of normal tissue formation often observed in conventional transgenic models due to the ectopic expression of the gene of interest during development. In parallel, using cell lines derived from pancreatic neuroendocrine cell tumors in <i>RIP-Tag; RIP-tva</i> mice, further biochemical and cellular analysis can be easily performed <i>in vitro</i>.
                    <br/><br/>
                    <img
                        src="/images/resource-figure1.jpg"
                        className="figures"
                        alt="Figure 1. Scheme for assessing candidate genes in RIP-Tag; RIP-tva."
                        title="Figure 1. Scheme for assessing candidate genes in RIP-Tag; RIP-tva."
                    />
                </div>
                <br/><hr className="dashed"/><br/>
                
                <div className="in-page-title">
                    The RCAS-tva system
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    <Anchor url="https://ccr.cancer.gov/hiv-dynamics-and-replication-program/resources/rcas-system"/>
                    <br/><br/>
                </div>
                <br/><hr className="dashed"/><br/>
                
                <div className="in-page-title">
                    Doxycycline-inducible PyMT mouse model
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    The cellular origins of the various forms of pancreatic cancer have not been resolved. It is not known whether different pancreatic tumor types arise from transformation or transdifferentiation of different target cells or whether they arise from a common precursor, with tumor types determined by the specific genetic alterations. The pancreas is composed of ductal, acinar, and endocrine cells, which are morphologically and functionally distinct. Resembling the physiologic and cellular diversity of the pancreas is a spectrum of pancreatic malignancies that possess histological and molecular features that recapitulate to some degree the properties of their normal cellular counterparts. In addition, pancreatic tumors with mixed differentiation have been found. 
                    <br/><br/>
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    Previous studies suggested that experimental pancreatic ductal carcinomas might be induced by polyoma middle T antigen (<i>PyMT</i>) expressed in non-ductal cells (Yoshida and Hanahan, <i>Am J Pathol</i>, 1994; Lewis <i>et al.</i>, <i>Genes Dev</i>, 2003). To ask whether <i>PyMT</i> transforms and transdifferentiates endocrine cells toward exocrine tumor phenotypes, we have generated a transgenic mouse line that inducibly expresses the <i>PyMT</i> oncogene and the linked <i>Luciferase</i> reporter (<i>tet-o-PyMT-IRES-Luciferase</i>) in different cell types. Luciferase bioluminescence emission facilitates monitoring transgene expression in living mice. We found that the ability of <i>PyMT</i> to induce tumorigenesis is dependent upon cell lineages. Conditional activation of <i>PyMT</i> in pancreatic &beta; cells of transgenic mice led to irreversible expansion of the &beta; cell population regardless of the developmental stage at which it was expressed (Figure 2). However, activation of <i>PyMT</i> starting from pancreatic precursor cells induced either lethal acinar cell carcinomas or &beta;-cell hyperplasia. Although continued expression of <i>PyMT</i> was required for maintenance of acinar cell carcinomas, it was not required to maintain the survival of the expanded &beta; cell population, in contrast with all other studies on acquired dependence of hyperplastic/tumor cells on an activated oncogene for their survival.
                    <br/><br/>
                </div>	
                <div>
                    <div className="paragraph-start"></div>
                    While losses of functional <i>p53</i> and <i>p16<sup>Ink4a</sup>/p19<sup>Arf</sup></i> have been identified in human pancreatic acinar cell carcinoma (PACC) and pancreatic neuroendocrine tumors (PanNETs), their roles in promoting tumorigenesis of the two pancreatic tumors types has not been previously investigated. We demonstrate that <i>p53</i> loss in pancreatic progenitor cells results in aggressive PACC, whereas <i>p16<sup>Ink4a</sup>/p19<sup>Arf</sup></i> loss results in PanNETs in the context of <i>PyMT</i> induction. In a model of &beta;-cell hyperplasia, <i>p53</i> and <i>p16<sup>Ink4a</sup>/p19<sup>Arf</sup></i> play cooperative roles in constraining the progression of PanNETs.
                    <br/><br/>
                    <img 
                        src="/images/resource-figure2.jpg"
                        className="figures"
                        alt="Figure 2. Representative images of enlarged, insulin-positive islets from the pancreas of RIP-rtTA; tet-o-PyMT-IRES-Luc bitransgenic mice after induced with doxycycline (Dox)."
                        title="Figure 2. Representative images of enlarged, insulin-positive islets from the pancreas of RIP-rtTA; tet-o-PyMT-IRES-Luc bitransgenic mice after induced with doxycycline (Dox)."
                    />
                    <br/><br/>
                </div>                
            </article>
        </section>
    );
}

export default Resources;