import Anchor from './Anchor';
import { menuItems } from '../infra/constants';
import { setMenuLabel } from '../infra/functions';

const PageNotFound = () => {
    return (
        <section className="page">
            <div className="page-header">
                <div className="page-title">Page Not Found</div>
            </div>
            <article className="page-content">
                <div>
                    <b>We can't find the page you are looking for, please try the links below.</b>
                </div>
                <div>
                    <ul>
                        {
                            menuItems.map((m, idx) => <li><Anchor key={m} url={'/' + m} label={setMenuLabel(m)} target="_self"/></li>)
                        }
                    </ul>
                </div>            
            </article>
        </section>
    );
}

export default PageNotFound;