const Footer = () => {
    const year = new Date().getFullYear();
    const displayStagingRibbon = {
        display: document.location.href.indexOf('staging.') !== -1 ? 'block' : 'none'
    }
    return (
        <footer>
			<div className="copy-rights">
                <div>&copy; {year} Laboratory of Nancy Du, PhD.&nbsp;</div>
                <div>All rights reserved.</div>
            </div>
            <div className="staging-ribbon" style={displayStagingRibbon}>
                <img src="https://dannystudio.dev/staging.png" alt="This is staging site"/>
            </div>
		</footer>
    );
}

export default Footer;