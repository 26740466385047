import Anchor from './Anchor';

const JoinOurTeam = () => {
    return (
        <section className="page">
            <div className="page-header">
                <div className="page-title">Join Our Team</div>
            </div>
            <article className="page-content">
                <div className="in-page-title">
                    Postdoctoral Fellows
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    We welcome highly motivated candidates with a recent Ph.D. degree in the field of molecular biology, cancer biology, developmental biology, genomics, or genetics.  Ph.D. candidates expected graduation are also encouraged to apply.  Applicant must be fluent in English, collegial and able to work independently, with at least one recent first author publication in a high-impact journal.  High levels of critical thinking and strong troubleshooting ability are expected.
                    <br/><br/>
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    To apply for a postdoctoral fellowship in the laboratory, please send the following information to Dr. Nancy Du:<br/>
                    <div className="indent-double">
                        <ul className="indent-double">
                            <li>A detailed curriculum vitae</li>
                            <li>Names and contact information of 3 references</li>
                            <li>Why you are interested in our lab and your post-doctoral training goals</li>
                        </ul>
                    </div>
                    <br/><br/>
                </div>
                <br/><br/>
                
                <div className="in-page-title">
                    Graduate Students
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    Our laboratory does not admit graduate students directly.  Please apply Weill Cornell Graduate School programs in Biochemistry & Structural Biology, <u>C</u>ell & Developmental Biology, and <u>M</u>olecular <u>B</u>iology  (<Anchor url="http://gradschool.weill.cornell.edu/"/>) or Tri-Institutional MD-PhD Program (<Anchor url="http://www.med.cornell.edu/mdphd"/>).  We welcome graduate students interested in rotating in the lab and conducting PhD thesis research.
                </div>
                <br/><br/>
                
                <div className="in-page-title">
                    Medical Students
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    We welcome highly motivated medical students. To apply, please send a cover letter with available hours, CV, contact information for 2 references in one PDF file to Dr. Du. 
                </div>
                <br/><br/>			
                
                <div className="in-page-title">
                    Undergraduate Students
                </div>
                <div>
                    <div className="paragraph-start"></div>
                    We accept undergraduate students from Macaulay Honors at Hunter College. Undergraduate students who make significant contributions to research projects can become co-authors of research papers.  If you are interested in joining our lab, please contact Dr. Nancy Du by email and include your resume, a transcript, your hours for lab research, and names and contact information of at least 2 references.  Priority will be given to students with genuine interest, a strong commitment in research, and excellent grade in relevant science courses.
                    <br/><br/>
                </div>                
            </article>
        </section>
    );
}

export default JoinOurTeam;