import { useRef } from 'react';

const AlumniTemplate = (props) => {
    const rowClassName = props.index === 0 ? '' : 'dashed';
    const detailVisible = useRef(false);

    const toggleAlumiDetails = (obj) => {
        const detailDiv = obj.target.nextSibling;
        detailDiv.style.gridTemplateRows = detailVisible.current ? '0fr' : '1fr';
        detailVisible.current = !detailVisible.current;
    }    

    return (
        <div className={rowClassName}>
            <div className="alumni-name" onClick={toggleAlumiDetails}>{props.name}, {props.title} {props.year}</div>
            <div className="alumi-details">
                <div>
                    <b>Accomplishments</b>
                    <div className="accomplishments">{props.accomplishments}</div>
                    {props.education ? 
                            <>
                            <b>Education</b>
                            <div className="education">{props.education}</div>
                            </>
                        : ''
                    }
                    {props.now ?
                            <>
                            <b>Current Position</b>
                            <div className="current-position">{props.now}</div>
                            </>
                        : ''
                    }
                </div>
            </div>
        </div>
    );
}

export default AlumniTemplate;