const Keywords = () => {
    return (
        <div className="keywords">
            Cancer Search,
            Molecular Mechanisms,
            Cancer Metastasis,
            Mouse Model,
            Pancreatic Cancer,
            TVA,
            Tag,
            PyMT,
            RHAMM,
            Bcl-xL
        </div>
    );
}

export default Keywords;