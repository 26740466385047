import Anchor from './Anchor';

const PublicationTemplate = (props) => {
    const rowClassName = props.index === 0 ? '' : 'dashed';

    return (
        <div className={rowClassName}>
            <div className="publication-item">
                {props.authors}. <Anchor url={props.link} label={props.label}/>. {props.publisher}.
            </div>
        </div>
    );
}

export default PublicationTemplate;