import Anchor from './Anchor';

const Donate = () => {
    const handleButtonClick = () => {
        window.open('https://s010.med.cornell.edu/wcmc/make-a-donation.html?dept=Laboratory of Nancy Du, PhD');
    }

    return (
        <section className="page">
            <div className="page-header">
                <div className="page-title">Donate</div>
            </div>
            <article className="page-content">
                <div className="in-page-title">
                    Support Cancer Research Today!
                </div>
                <div>
                    Your gift will make a difference. Help us develop novel therapeutic strategies to save lives of cancer patients. Please consider making a tax-deductible donation to support our ongoing research.<br/>
                    <div><button onClick={handleButtonClick} className="donate-button">Donate</button></div>
                    <br/><br/>
                    For other donation methods, please contact
                    <div className="indent-double">
                        Ms. Giulia Gabrielli<br/>
                        Principal Gifts Officer<br/>
                        Weill Cornell Medicine<br/>
                        1300 York Avenue, Box 314<br/>
                        New York, NY 10065<br/>
                        TEL: +1.646.962.9574<br/>
                        Email: <Anchor url="mailto:gig2006@med.cornell.edu" label="gig2006@med.cornell.edu" target="_self"/>
                        <br/><br/>
                    </div>
                    <div className="indent-double">
                        Mr. Tom Nichols<br/>
                        Major Gifts Officer<br/>
                        Weill Cornell Medicine<br/>
                        Office of External Affairs<br/>
                        1300 York Avenue, Box 314<br/>
                        New York, NY  10065<br/>
                        TEL:  +1.646.962.9524<br/>
                        Email: <Anchor url="mailto:thn2004@med.cornell.edu" label="thn2004@med.cornell.edu" target="_self"/>
                        <br/><br/>
                    </div>
                </div>                
            </article>
        </section>
    );
}

export default Donate;