import Anchor from './Anchor';

const Contact = () => {
    return (
        <section className="page">
            <div className="page-header">
                <div className="page-title">Contact</div>
            </div>
            <article className="page-content">
                <div className="in-page-title">
                    Shipping/Overnight Address
                </div>
                <div className="indent-double">
                    Dr. Nancy Du<br/>
                    413 E. 69 th Street, C440B<br/>
                    New York, NY 10021<br/>
                </div>
            
            
                <div className="in-page-title">
                    USPS Mailing Address
                </div>
                <div className="indent-double">
                    Dr. Nancy Du<br/>
                    Department of Pathology and Laboratory Medicine<br/>
                    Weill Cornell Medical College<br/>
                    1300 York Avenue, Box 69<br/>
                    New York, NY 10065<br/>
                </div>
            
                <div className="in-page-title">
                    Email Address
                </div>
                <div className="indent-double">
                    <Anchor url="mailto:nad2012@med.cornell.edu" label="nad2012@med.cornell.edu" target="_self"/>
                    <br/>
                </div>
                <div className="in-page-title">
                    Lab Phone
                </div>
                <div className="indent-double">
                    +1.212.746.4524
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                </div>                
            </article>
        </section>
    );
}

export default Contact;