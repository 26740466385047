const TeamMemberProfile = (props) => {
    const imgTitle = `${props.title} ${props.name}`;
    return (
        <div className="member-item">
            <div className="member-title">{props.title}</div>
            <figure>
                <img className="member-photo" src={`/images/${props.avatar}`} alt={imgTitle} title={imgTitle}/>
                <figcaption>{props.name}</figcaption>
                <br/>
                <br/>
            </figure>					
        </div>
    );
}

export default TeamMemberProfile;