import PublicationTemplate from './PublicationTemplate';
import { publications } from '../data/Publications';

const Publications = () => {
    return (
        <section className="page">
            <div className="page-header">
                <div className="page-title">Publications</div>
            </div>
            <article className="page-content">
            {
                publications.map((publication, index) => {
                    return (
                        <PublicationTemplate
                            index={index}
                            authors={publication.authors}
                            link={publication.link}
                            label={publication.label}
                            publisher={publication.publisher}
                        />
                    );
                })
            }    
            </article>
        </section>
    );
}

export default Publications;