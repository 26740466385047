import Menu from './Menu';

const Header = () => {
    return (
        <header className="header">
            <div className="title-wrapper">
                <div className="title">Nancy Du Lab</div>
                <div className="tagline">Exploring molecular mechanisms of cancer metastasis</div>
            </div>
            <Menu/>
        </header>
    );
}

export default Header;